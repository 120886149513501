import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-how-are-students-encouraged-to-make-good-choices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-how-are-students-encouraged-to-make-good-choices",
        "aria-label": "a how are students encouraged to make good choices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. How Are Students Encouraged To Make Good Choices?`}</h2>
    <h4 {...{
      "id": "zones-of-regulaton",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#zones-of-regulaton",
        "aria-label": "zones of regulaton permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zones of Regulaton`}</h4>
    <p>{`Our Zones of Regulation programme is used throughout the whole of Primary School. Students learn to identify and regulate their emotions according to four colours, as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Green zone means the child’s emotions are calm and they are ready to learn.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Blue zone means that the child is tired, sad or not feeling well.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Red zone means the child is angry or feeling agitated.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Yellow zone means the child is frustrated or feeling anxious or worried.`}</p>
      </li>
    </ul>
    <p>{`While we want all of our children to be in the green zone, there are times they will find themselves in one of the other zones. As we teach Primary students the zones, we also teach them strategies to regulate their emotions and move them back to the green zone.`}</p>
    <p>{`For disagreements and smaller problems, the following steps should be followed by the students before going to an adult. Adults also guide students to use these steps before the adult intervenes to solve the problem for the student.`}</p>
    <h4 {...{
      "id": "panthers-choices-for-kg---g2-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#panthers-choices-for-kg---g2-",
        "aria-label": "panthers choices for kg   g2  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther’s Choices (for KG - G2) *`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Talk it out - Use an ‘I message’`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Walk away`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ignore it`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tell the person to stop`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Apologise`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make a deal`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wait and cool off`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Go to another game`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Share and take turns`}</p>
      </li>
    </ul>
    <p>{`OR`}</p>
    <h4 {...{
      "id": "the-panthers-winwin-guidelines-for-grades-3---5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#the-panthers-winwin-guidelines-for-grades-3---5",
        "aria-label": "the panthers winwin guidelines for grades 3   5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Panther’s Win/Win Guidelines (for Grades 3 - 5)`}</h4>
    <p>{`1`}{`.`}{` If you are angry or upset, take a minute to cool off.`}</p>
    <p>{`2`}{`.`}{` Tell the person how you feel and what you want using an ‘I message’`}</p>
    <p>{`3`}{`.`}{` Listen to the other person carefully`}</p>
    <p>{`4`}{`.`}{` Take responsibility for your part of the problem`}</p>
    <p>{`5`}{`.`}{` Think of solutions`}</p>
    <p>{`6`}{`.`}{` Choose the best solution for both of you.`}</p>
    <p><strong parentName="p">{` Remember `}</strong>{`: If it’s a big problem, students should tell an adult they trust right away. (This can be the teacher on recess duty, class teacher, counsellor, or principal.)`}</p>
    <p>{`Breaking rules or engaging in totally unacceptable behaviour (physical aggression or hurtful and abusive language) may result in `}<strong parentName="p">{`disciplinary action.`}</strong>{` A common approach might be a detention during lunch recess or an ECA, an in-school suspension in the principal’s office, or at times, an out of school suspension. Very serious or repeated offences can lead to expulsion. Please be reminded that the general guidelines for behaviour are on page 64.`}</p>
    <h2 {...{
      "id": "b-how-do-teachers-and-administrators-support-students-in-making-good-choices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-do-teachers-and-administrators-support-students-in-making-good-choices",
        "aria-label": "b how do teachers and administrators support students in making good choices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Do Teachers And Administrators Support Students In Making Good Choices?`}</h2>
    <h4 {...{
      "id": "when-helping-students-solve-problems-teachers-and-the-principal-might-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#when-helping-students-solve-problems-teachers-and-the-principal-might-",
        "aria-label": "when helping students solve problems teachers and the principal might  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`When helping students solve problems, teachers and the principal might …`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Help students talk and listen to each other when solving a problem`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Listen to the reporting student and write a report of the incident`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Talk to the person who initiated the problem`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove the person(s) from the class or yard`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Send the person(s) to the principal if it is repeated or totally unacceptable behaviour`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Speak to the parents about the behaviour`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "when-dealing-with-problems-teachers-and-the-primary-principal-will-always-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#when-dealing-with-problems-teachers-and-the-primary-principal-will-always-",
        "aria-label": "when dealing with problems teachers and the primary principal will always  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`When dealing with problems, teachers and the primary principal will always …`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Listen to both sides of the story and all the students involved (including any observers)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Let students know that reporting is sensible`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Work out the best way to handle the situation together with the student`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Let students know the result`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Report incidents and results thoroughly to families for their information.`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "academic-integrity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#academic-integrity",
        "aria-label": "academic integrity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Academic Integrity`}</h4>
    <ul>
      <li parentName="ul">{`All students are expected to maintain academic integrity with respect to their academic work. It is unacceptable to submit someone else's work as their own work, give their work to someone else to use, copy homework, or to use notes, signal or talk during a test. In primary school, students begin to learn how to summarize and paraphrase content in texts and, as an upper primary student, learn about plagiarism and the importance of doing their own work when called upon. A complete explanation of the definitions, the roles and responsibilities, the consequences and the support systems may be found in the `}<a parentName="li" {...{
          "href": "https://docs.google.com/document/d/1N1BYZflvdgjmz6ptitjvS_0eQ2eamfa-giMg1HoFPw4/edit"
        }}>{` Primary Academic Integrity Protocols `}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "c-what-are-the-consequences-for-students-who-make-poor-choices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#c-what-are-the-consequences-for-students-who-make-poor-choices",
        "aria-label": "c what are the consequences for students who make poor choices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`C. What Are The Consequences For Students Who Make Poor Choices?`}</h2>
    <h4 {...{
      "id": "level-one---inappropriate-choices-classroom--teacher-level",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#level-one---inappropriate-choices-classroom--teacher-level",
        "aria-label": "level one   inappropriate choices classroom  teacher level permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Level One - Inappropriate Choices (Classroom / Teacher Level)`}</h4>
    <p>{`It is understood that children at this age are learning how to behave and interact with others outside the family. At times, children test limits or simply do not know how to make the right choice. They may get frustrated or angry and may not react in a socially acceptable way. These are called ‘inappropriate behaviours’.`}</p>
    <p>{`At Level One, all classroom teachers and specialist teachers establish their own classroom expectations, grievance procedures, and consequences.They also issue appropriate re-directions, warnings and counselling. Teachers determine whether specific behaviours are significant enough or repeated often enough that they require further action at the administrative level.`}</p>
    <h4 {...{
      "id": "level-two---unacceptable-behaviour-primary-principal--deputy-principal-level",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#level-two---unacceptable-behaviour-primary-principal--deputy-principal-level",
        "aria-label": "level two   unacceptable behaviour primary principal  deputy principal level permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Level Two - Unacceptable Behaviour (Primary Principal / Deputy Principal Level)`}</h4>
    <p>{`This may include refusal / not following a staff member’s directions, physical aggression, inappropriate language, and / or repeated inappropriate behaviours. When a teacher sends a student to an administrator for a behaviour issue, the meeting is divided into two parts: Learning from our mistakes and accepting consequences for our actions.`}</p>
    <p>{`Wherever possible, consequences are meant to reinforce the behaviour that needs correcting. Consequences can vary depending on the maturity level of the child. For example, students in Grades 4 and 5 are considered old enough to refrain from physical aggression on campus at all times, so consequences are more serious.`}</p>
    <p>{`Repeated unacceptable behaviour will lead to the student and their parents meeting with the Primary principal, after which the student may be placed on a behavioural contract.`}</p>
    <h4 {...{
      "id": "level-three--totally-unacceptable-behaviour-primary-principal--deputy-principal-level",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#level-three--totally-unacceptable-behaviour-primary-principal--deputy-principal-level",
        "aria-label": "level three  totally unacceptable behaviour primary principal  deputy principal level permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Level Three – Totally Unacceptable Behaviour (Primary Principal / Deputy Principal Level)`}</h4>
    <p>{`This may include fighting, excessive physical behaviour, violence, biting, repeated harassment and / or repeated refusals to follow an adult's instruction.`}</p>
    <ul>
      <li parentName="ul">{`Suspension *`}</li>
    </ul>
    <p>{`Suspensions of students, either in-school and out of school, occur for serious offenses and can vary in length. In these instances, students are immediately and officially suspended from school. An official letter of suspension is issued to the family involved.`}</p>
    <p>{`For in-school suspensions, the student must attend school but remain in the principal’s or deputy principal’s office for the day(s) the child is suspended. Teachers will give work to the student to do while suspended.`}</p>
    <p>{`For out of school suspension, the student is sent home for one or more days. This means that they cannot attend any school events during the time of that suspension, but they are expected to keep current in class work.`}</p>
    <p>{`All students involved in a discipline issue, including students who may have been frightened or harmed by another student, receive assistance from our Primary School counsellor. This assistance can be in the form of individual conversations, weekly meetings, discussions among small groups of children, whole class lessons and more.`}</p>
    <p>{`In order to return to school, the parents and the child must meet with the Primary principal. The student must exhibit a willingness to return to class. A behavioural or probationary contract is developed for a period that may range from one month to the rest of the school year. This is reviewed before the student moves to the next grade level.`}</p>
    <p>{`PSI reserves the right to deny enrolment to any student for the next school year if the student and / or parent does not demonstrate a commitment to and support of the child’s behavioural development. (See page 45 for our parent code of conduct.)`}</p>
    <p>{`Please note that the School reserves the right to increase the consequences listed above based on the severity of the incident and the pattern of behaviour for individual students. It is important to note that two suspensions within one calendar year may result in a referral to the school’s Expulsion Committee.`}</p>
    <h4 {...{
      "id": "level-four--expulsion-primary-principal--deputy-principal--school-director--board-of-governors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#level-four--expulsion-primary-principal--deputy-principal--school-director--board-of-governors",
        "aria-label": "level four  expulsion primary principal  deputy principal  school director  board of governors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Level Four – Expulsion (Primary Principal / Deputy Principal / School Director / Board of Governors)`}</h4>
    <p>{`Expulsion of students occurs for any acts that seriously threaten the safety of students and staff and / or for repeated acts of misconduct despite other disciplinary actions. (See examples on page 68).`}</p>
    <p>{`The School convenes an Expulsion Committee made up of the homeroom teacher or PYP coordinator, the principal, the director, and at least one board member. The student or parent may also request a specific teacher to serve as an advocate; this teacher is a non-voting member of the committee. Based on evidence presented and input from the parents and other teachers, the committee decides whether or not to expel. The decision to expel must be unanimous. Students who are expelled are not allowed to return to PSI at any time, either to re-enrol or to visit the campus.`}</p>
    <p><strong parentName="p">{`NOTE:`}</strong>{` The school also reserves the right to immediately move past the first three steps for serious incidents. Under serious circumstances, the school school leadership may choose to suspend or expel without going through all the steps involved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      