import React, { useLayoutEffect }    from 'react';
import { MDXProvider }               from '@mdx-js/react';
import { PageProps }                 from 'gatsby';
import { ModalRoutingContext }       from 'gatsby-plugin-modal-routing';
import { Wrapper, WrapperPropsType } from 'lib/components/wrapper/wrapper.component';
import PropTypes                     from 'prop-types';
import { Divider, DividerPropsType } from '../../components/divider/divider';
import { Table }                     from '../../components/table/table';
import { Text, TextPropsType }       from '../../components/text/text';
import { Title, TitlePropsType }     from '../../components/title/title';
import { Navigation }                from '../../container/navigation/navigation';
import { cn }                        from '../../util/bem';
import { md }                        from '../../util/md';
import './post-layout.scss';

const components = {
    h1:         (props: TitlePropsType) => <Title size="h1" margin="xxl" { ...props } />,
    h2:         (props: TitlePropsType) => <Title color="key" size="h3" margin="md" sm={ { size: 'h4' } } { ...props } />,
    h3:         (props: TitlePropsType) => <Title size="h4" xsm={ { size: 'h4' } } margin="md" { ...props } />,
    h4:         (props: TitlePropsType) => <Title size="h4" margin="md" { ...props } />,
    p:          (props: TextPropsType) => <Text className={ cn('paragraph')() } margin="md" { ...props } />,
    blockquote: (props: TitlePropsType) => <Title size="h3" xsm={ { size: 'h4' } } margin="md" { ...props } />,
    Table:      (props: TitlePropsType) => <Table { ...props } />,
    Wrapper:    (props: WrapperPropsType) => <Wrapper { ...props } />,
    Divider:    (props: DividerPropsType) => <Divider { ...props } />
};
const className = cn('post-layout');

export const PostLayoutHandbook = (props: PageProps & {title: string; url: string}) => {
    useLayoutEffect(() => {
        setTimeout(() => {
            if (props.location.hash) {
                const header = document.getElementById(props.location.hash.substring(1));
                console.log(props.location.hash);
                const scrollArea = document.getElementsByClassName('ReactModal__Content')[ 0 ];
                if (header && scrollArea) {
                    scrollArea.scrollTop = header.offsetTop;
                }
            }
        }, 1);
    });
    const metaId = props.path.split('/')[ 2 ];
    console.log('post-layout', props.path.split('/')[ 2 ]);

    const meta = {
        primary: {
            title: 'Primary Programme Guide',
            url:   '/handbook/primary/'
        },
        secondary: {
            title: 'Secondary Programme Guide',
            url:   '/handbook/secondary/'
        },
        'parent-student': {
            title: 'Parent Student Handbook',
            url:   '/handbook/parent-student/'
        }
    };

    // const getMeta = () => {
    //     if (props.location && props.location.pathname) {
    //         if (props.location.pathname.includes('primary-programme-guide')) {
    //             return {
    //                 title: 'Primary Programme Guide',
    //                 url:   '/handbook/primary/'
    //             };
    //         }
    //         if (props.location.pathname.includes('secondary-programme-guide')) {
    //             return {
    //                 title: 'Secondary Programme Guide',
    //                 url:   '/handbook/secondary/'
    //             };
    //         }
    //         if (props.location.includes('parent-student-handbook')) {
    //             return {
    //                 title: 'Parent Student Handbook',
    //                 url:   '/handbook/parent-student/'
    //             };
    //         }
    //     }
    // };
    return (
        <ModalRoutingContext.Consumer>
            { ({ modal, closeTo }) => (
                <MDXProvider components={ components }>
                    <Wrapper className={ className('handbook') } padding="xxl" align="left">
                        <Wrapper
                            maxWidth="800px"
                            styles={ { minHeight: '100vh', paddingTop: '10rem' } }
                            margin="xxl"
                            padding={ { left: 'xxl' } }
                            sm={ { padding: { left: 'off' } } }
                            style={ { display: 'block' } }
                        >
                            <Title
                                size="h1"
                                margin="md"
                                maxWidth="800px"
                                sm={ { size: 'h2' } }
                                fullWidth
                            >
                                { md(props.pageContext.frontmatter?.title) }
                            </Title>
                            <Divider transparent margin="xxl" dashed />
                            <Wrapper>{ props.children }</Wrapper>
                        </Wrapper>

                        <Navigation
                            meta={ props.title }
                            isModal={ modal }
                            closeTo={ closeTo }
                            to={ props.url }
                        />
                    </Wrapper>
                </MDXProvider>
            ) }
        </ModalRoutingContext.Consumer>
    );
};
PostLayoutHandbook.propTypes = { children: PropTypes.node.isRequired };

export default PostLayoutHandbook;
